export const blue = "#1C1C53";
export const aquaBlue = "#DFDFF4";
export const lightBlue = "#F9F9FD";
export const green = "#4D8B31";
export const green2 = "#4D8A31";
export const lightGray = "#c5c5c5";
export const faintGray = "#eeeeee";
export const gray = "#888888";
export const gray1 = "#454545";
export const lightGray2 = "#F6F6F6";
export const white = "#f3f3f3";
export const pink = "#f25151";
export const gray2 = "#bbbbbb";
export const white1 = "#ffffff";
export const whiteBg = "#fafafa";
export const yellow = "#FECF0A";
export const black = "#000000";
export const gray3 = "#1C1C5326";
export const skyBlue = "#6EBCFD";
export const lightGreen = "#7DC55C";
export const red = "#ED1212";
export const darkGreen = "#31581F";
export const red1 = "#f44336";
export const primaryBlue = "#cce5ff";
export const darkBlue = "#004085";
export const faintBlue = "#b8daff";
export const faintBlue2 = "#ececf9";
export const primary = "#007ee5";
export const faintBlack = "#00000052";
export const darkGray = "#BBBBBB";
export const flamingo = "#F25E25";
export const lightOrange = "#F9B195";
export const darkOrange = "#C9400A";
export const caribbeanGreen = "#03B99B";
export const whiteLilac = "#ececf9";
export const azureRadiance = "#057CE5";
export const tundora = "#454545";
export const alabaster = "#F7F7F7";
export const Shark = "#252529";
export const Ebb = "#f1eded";
export const wildSand = "#f5f5f5";
export const alto = "#DBDBDB";
export const chetwodeBlue = "#8884d8";
export const concrete = "#f3f2f2";
export const pastelGreen = "#8BDB60";
export const frost = "#F0FAEB";
export const malibu = "#5DB6FF";
export const onahau = "#C3E4FF";
export const mySin = "#FFBB28";
export const carol = "#FF8042";
export const celadon = "#B9E0A7";
export const mercury = "#e8e8e8";
export const supernova = "#FED109";
export const violet = "#fb8bf2";
export const yellowGreen = "#8cdb60";
export const navajoWhite = "#facda3";
export const lightSteelBlue = "#babffe";
export const gainsBoro = "#EBF6E600";
export const lavendar = "#e5e1ff";
export const doveGray = "#6D6D6D";
export const paleTurquoise = "#c2e3fe";
export const mediumSeaGreen = "#2CC877";
export const paleBlue = "#b8ddfd";
export const midnightblue = "#1b1b53";
export const portGore = "#1C1C53";
export const silver = "#C2C2C2";
export const lightPink = "#ffa1a1";
export const lightsLateGray = "#9270ab";
export const paleVioletRed = "#e96682";
export const fadedGray = "#666666";
export const oldLace = "#D8FE0A14";
export const gold = "#F6C703";
export const khaki = "#fde36f";
export const azureBlue = "#EBFDFF";
export const fireBrick = "#c9400b";
export const slateBlue = "#4747c1";
export const azure = "#ecfdff";
export const apple = "#4A8F3B";
export const sunglow = "#FFCC39";
export const bunting = "#171D51";
export const gallery = "#ECECEC";
export const iron = "#DFE3E2";
export const tiara = "#CED2D1";
export const porcelain = "#F0F3F3";
export const dodgerBlue = "#1890FF";
