import styled from "styled-components";
import { white1, green, gray, green2, blue, azureRadiance } from "../../shared/colors";

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  padding-top: 90px;

  a {
    color: inherit;
    text-decoration: inherit;
  }

  @media (min-width: 320px) and (max-width: 480px) {
    flex-wrap: wrap;
  }
`;

export const Body = styled.div`
  justify-content: center;
`;

export const Button = styled.button`
  height: 30px;
  width: 300px;
  background: ${green2};
  border-radius: 4px;
  text-align: center;
  letter-spacing: 0;
  color: ${white1};
  cursor: pointer;
  font-size: 18px;
  outline: 0;
  border: none;
`;

export const Contact = styled.span`
  top: 23px;
  left: 171px;
  width: 138px;
  height: 21px;
  text-align: center;
  text-decoration: underline;
  font: SemiBold 15px/23px Poppins;
  letter-spacing: 0;
  color: ${green};
`;

export const ErrorText = styled.h2`
  top: 163px;
  left: 30px;
  width: 300px;
  height: 33px;
  text-align: left;
  font: SemiBold 24px/35px Poppins;
  letter-spacing: 0;
  color: ${gray};
`;

export const Instruction = styled.div`
  left: 30px;
  width: 300px;
  height: 140px;
  text-align: left;
  font: SemiBold 15px/23px Poppins;
  letter-spacing: 0;
  opacity: 1;
`;
export const ErrorImage = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  max-width: 312px;

  span {
    text-align: left;
    color: ${gray};
    font-weight: 600;
  }
`;

export const Title = styled.div`
  margin-top: -80px;  
  height: 40px;
  font-weight: 600;
  font-size: 20px;
  color: ${blue};
  text-transform: uppercase;
`;

export const Main = styled.div`
  text-align: center;
  width: 480px;
  height: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 130px;

  span {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    color: ${gray};
  }

  img {
    height: 330px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: block;
  }
`;

export const TryAgain = styled.button`
  width: 80px;
  height: 30px;
  background:${azureRadiance};
  border-radius: 4px;
  border: none;
  text-align: center;
  font-weight: 600;
  font-size: 13px;
  color: ${white1};
  margin-top: 5px;
  cursor: pointer;
  outline: none;
`;
