import styled from "styled-components";
import { Link } from "react-router-dom";
import icon, { LoginBanner } from "assets/index";
import {
  blue,
  lightBlue,
  lightGray,
  gray,
  white,
  aquaBlue,
  white1,
  yellow,
  azureRadiance,
  alabaster
} from "./colors";

export const Button = styled.button`
  color: white;
  border-radius: 4px;
  font-weight: 600;
  outline: none;
  cursor: pointer;
  border: none;
  text-align: center;

  .fa {
    margin-left: -12px;
    margin-right: 8px;
  }

  &.signupButton {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 14px 100px;
    background: ${yellow};
    color: ${blue};
    font-size: 15px;
  }

  &.loginButton {
    width: 172px;
    height: 30px;
    background: ${yellow};
    text-align: center;
    color: ${blue};
    font-size: 13px;
  }

  &.resetPassword {
    margin-top: 20px;
    padding: 10px 74px;
    background: ${yellow};
    color: ${blue};
    font-size: 15px;
    margin-bottom: 25px;
  }

  &:disabled {
    opacity: 0.6;
    pointer-events: none;
  }
`;

export const InputDiv = styled.div`
  flex: 1;
  margin-right: ${props => props.primary ? "10px" : "0"};
  margin-left: ${props => props.secondary ? "10px" : "0"};
  margin-bottom: 15px;
`;

export const InputText = styled.div`
  position: relative;
  margin-top: ${props => (props.login ? "24px" : "0")};

  input {
    width: -webkit-fill-available;
    height: 35px;
    border-radius: 4px;
    background: transparent;
    outline: none;
    font-size: 15px;
    border: 1px solid ${gray};
    padding-left: 10px;
    font-weight: 500;

    &.has-icon {
      padding-left: 40px;
      border: none;
      background: ${lightBlue};
    }
  }

  .icon {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 14px;
    top: 10px;
    background: ${props => (props.icon ? icon() : null)};
  }

  li {
    outline: none;
  }
`;

export const Label = styled.label`
  height: 19px;
  font-weight: 600;
  letter-spacing: 0;
  color: ${blue};
`;

export const BodyArea = styled.div`
  display: flex;
  background: ${lightBlue};

  .imageContainer {
    min-width: 50%;
    background: url(${LoginBanner}) no-repeat left top;
    background-size: cover;
    padding: 52px;
    position: relative;
    height: 100vh;
    text-align: center;

    @media (min-width: 320px) and (max-width: 480px) {
      display: none;
    }

    .logo {
      margin-top: 90px;
      margin: auto;
      width: 400px;
      height: 200px;

      @media (min-width: 320px) and (max-width: 480px) {
        display: none;
      }
    }

  }
`;

export const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1 1 0%;
    margin: 154px 98px 157px 140px;
    max-width: 700px;

    @media (min-width: 320px) and (max-width: 480px) {
        margin: 20px 30px;
    }

    .formContainer {
      width: 100%;
      box-shadow: 0px 1px 3px ${alabaster};
      border-radius: 4px;
      margin-bottom: 20px;
      background-color: ${white1};
      max-width: 500px;

      .header {
        height: 60px;
        background: ${aquaBlue};
        border-radius: 4px 4px 0px 0px;
        text-align: center;
        vertical-align: middle;
        line-height: 60px;
        color: ${blue};
        font-weight: bold;
        margin-bottom: 38px;
      }

      &.first-child{
        margin-right: 5px;
      }

      &.last-child{
        margin-left: 5px;
      }

      hr {
        display: inline-block;
        width: 30%;
        height: 1px;
        border: 0;
        border-top: 1px solid ${lightGray};
        text-align: center;
        margin: 5px;
      }
  }
    .phone {
      margin: 0px 3px 20px 8px;
      flex: 1;
    }
`;

export const Footer = styled.div`
  display: block;
  font-size: 13px;
  color: ${blue};
  width: 100%;
  text-align: center;
  text-decoration: none;
  height: 19px;
`;

export const Account = styled.div`
  height: 19px;
  text-align: center;
  font-size: 13px;
  letter-spacing: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  color: ${gray};
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
  letter-spacing: 0;
  font-size: 13px;
  font-weight: 500;
  color: ${props => props.primary ? `${gray}` : `${azureRadiance}`};
`;

export const Loader = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  border: 5px solid ${white};
  border-radius: 50%;
  border-top: 5px solid ${blue};
  width: 40px;
  height: 40px;
  margin: -34px 0 0 -34px;
  animation: spin 1s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Spinner = styled.div`
  margin-left: 50%;
  border: 5px solid ${white};
  border-radius: 50%;
  border-top: 5px solid ${blue};
  width: 40px;
  height: 40px;
  top: 50%;
  margin-top: 150px
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;

  .loading1 {
    color: ${blue};
    font-weight: 600;
    text-align: center;
    font-size: 14px;
  }
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
`;

export const NoDataDiv = styled.tbody`
  top: 50%;
  left: 43%;
  position: absolute;
  margin-top: ${props => props.products ? "20px" : props.region ? "0" : "0px"};

  tr {
    border: none !important;
  }

  td {
    display: flex;
    flex-direction: column;
    border: none !important;

    img {
      width: 70px !important;
      height: 40px !important;
    }

    span {
      font-size: 14px !important;
      padding-left: 8px !important;
    }
  }
`;
