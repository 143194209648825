import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { AuthProvider } from "./hooks/authHook";
import BreadcrumbsProvider from "./hooks/breadCrumbsHook";
import UserAlertProvider from "hooks/useAlertHook";
import "./App.css";
import PrivateRoute from "./utils/PrivateRoute";
import { PageNotFound } from "components/ErrorPages/ErrorPage";
import { Loader } from "./components/shared/styles";
import ScrollToTop from "./utils/ScrollToTop";
import * as Sentry from "@sentry/react";

const ErrorBoundary = lazy(() =>
  import("./components/ErrorPages/ErrorBoundary")
);
const PageLayout = lazy(() => import("./components/Header/PageLayout"));
const LoginPage = lazy(() => import("./components/Auth"));
const UsersPage = lazy(() => import("components/Settings/Users"));
const AddUser = lazy(() => import("components/Settings/Users/AddUser"));
const EditUser = lazy(() => import("components/Settings/Users/EditUser"));
const MyProducts = lazy(() => import("./components/Dashboard/MyProducts"));
const GlobalStatistics = lazy(() =>
  import("./components/Dashboard/GlobalStatistics")
);
const General = lazy(() => import("./components/Settings/General"));
const Orders = lazy(() => import("./components/Purchasing/Orders"));
const PurchaseOrder = lazy(() =>
  import("./components/Purchasing/Orders/PurchaseOrder")
);
const PurchaseDeliveries = lazy(() =>
  import("./components/Purchasing/Deliveries")
);
const PaymentSlip = lazy(() =>
  import("./components/Purchasing/Deliveries/PaymentSlip")
);
const Portfolios = lazy(() => import("./components/Settings/Portfolios"));
const InventoryTracking = lazy(() => import("./components/Inventory"));
const InventoryDetails = lazy(() =>
  import("./components/Inventory/InventoryTracking/InventoryDetails")
);
const PaymentTracking = lazy(() => import("./components/Payment"));
const PaymentDetails = lazy(() => import("./components/Payment/PaymentTracking/PaymentDetails"));

const App = () => {
  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundary} showDialog>
      <AuthProvider>
        <BreadcrumbsProvider>
          <UserAlertProvider>
            <Router>
              <ScrollToTop />
              <Suspense fallback={<Loader />}>
                <Switch>
                  <Route
                    exact
                    path="/login"
                    render={() => (
                      <ErrorBoundary>
                        <LoginPage />
                      </ErrorBoundary>
                    )}
                  />
                  <Route
                    exact
                    path="/update_password/:uid"
                    render={() => (
                      <ErrorBoundary>
                        <LoginPage />
                      </ErrorBoundary>
                    )}
                  />
                  <Route
                    exact
                    path="/reset-password"
                    render={() => (
                      <ErrorBoundary>
                        <LoginPage />
                      </ErrorBoundary>
                    )}
                  />
                  <PageLayout>
                    <Suspense fallback={<Loader />}>
                      <Switch>
                        <PrivateRoute
                          exact
                          path="/settings/users"
                          component={UsersPage}
                        />
                        <PrivateRoute
                          exact
                          path="/settings/users/add-user"
                          component={AddUser}
                        />
                        <PrivateRoute
                          exact
                          path="/settings/users/add-user"
                          component={AddUser}
                        />
                        <PrivateRoute
                          exact
                          path="/settings/users/:uid"
                          component={EditUser}
                        />
                        <PrivateRoute
                          exact
                          path="/settings/general"
                          component={General}
                        />
                        <PrivateRoute
                          exact
                          path="/settings/portfolios"
                          component={Portfolios}
                        />
                        <Route exact path="/">
                          <Redirect to="/dashboard/my-products" />
                        </Route>
                        <PrivateRoute
                          exact
                          path="/dashboard/my-products"
                          component={MyProducts}
                        />
                        <PrivateRoute
                          exact
                          path="/dashboard/global-statistics"
                          component={GlobalStatistics}
                        />
                        <PrivateRoute
                          exact
                          path="/transactions/orders"
                          component={Orders}
                        />
                        <PrivateRoute
                          exact
                          path="/transactions/orders/:uid"
                          component={PurchaseOrder}
                        />
                        <PrivateRoute
                          exact
                          path="/transactions/purchases"
                          component={PurchaseDeliveries}
                        />
                        <PrivateRoute
                          exact
                          path="/transactions/purchases/:uid"
                          component={PaymentSlip}
                        />
                        <PrivateRoute
                          exact
                          path="/inventory/inventory-tracking"
                          component={InventoryTracking}
                        />
                        <PrivateRoute
                          exact
                          path="/inventory/inventory_details/:product_item_id"
                          component={InventoryDetails}
                        />
                        <PrivateRoute
                          exact
                          path="/payment/payment-tracking"
                          component={PaymentTracking}
                        />
                        <PrivateRoute
                          exact
                          path="/payment/payment_details/:product_item_id"
                          component={PaymentDetails}
                        />
                        <Route
                          path="*"
                          render={() => (
                            <ErrorBoundary>
                              <PageNotFound />
                            </ErrorBoundary>
                          )}
                        />
                      </Switch>
                    </Suspense>
                  </PageLayout>
                  <Route
                    exact
                    path="*"
                    render={() => (
                      <ErrorBoundary>
                        <PageNotFound />
                      </ErrorBoundary>
                    )}
                  />
                </Switch>
              </Suspense>
            </Router>
          </UserAlertProvider>
        </BreadcrumbsProvider>
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
};
export default App;
