import React, { useState } from "react";
import PropTypes from "prop-types";
import { BreadCrumbsContext } from "../context/breadcrumbsContext";

const BreadcrumbsProvider = ({ children }) => {
  const [mainPage, setMainPage] = useState("");
  const [home, setHome] = useState("");
  const [breadCrumb, setBreadCrumb] = useState("");

  return (
    <BreadCrumbsContext.Provider
      value={{
        mainPage,
        home,
        breadCrumb,
        setMainPage,
        setHome,
        setBreadCrumb
      }}
    >
      {children}
    </BreadCrumbsContext.Provider>
  );
};

BreadcrumbsProvider.propTypes = {
  children: PropTypes.node
};

export default BreadcrumbsProvider;
