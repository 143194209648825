import { ApolloClient, InMemoryCache, createHttpLink, from } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { get } from "lodash-es";
import { version } from "../../../package.json";

const { REACT_APP_BASEURL } = process.env;

const authLink = setContext((_, { headers }) => {
  const user = JSON.parse(localStorage.getItem("sokolytics_dashboard_user"));
  const accessToken = get(user, "token.access_token", "null");

  return {
    headers: {
      ...headers,
      "apollographql-client-name": "Sokolytics",
      "apollographql-client-version": version,
      authorization: `Bearer ${accessToken}`
    }
  };
});

const handleError = onError(({ networkError, graphQLErrors }) => {
  if (networkError && networkError.statusCode === 401) {
    localStorage.setItem("sokolytics_dashboard_user", null);
    window.location.href = "/login";
  }
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      );
    });
  }
});

const httpLink = createHttpLink({
  uri: `${REACT_APP_BASEURL}/graphql`
});

export const client = new ApolloClient({
  uri: `${REACT_APP_BASEURL}/graphql`,
  cache: new InMemoryCache(),
  link: from([authLink, handleError, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "ignore"
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all"
    }
  }
});
