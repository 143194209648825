import React, { createContext, useContext, useState } from "react";
import { useLocalStorage } from "@rehooks/local-storage";
import axiosClient from "utils/axiosClient/ApiClient";
import PropTypes from "prop-types";
import { formatError } from "utils/apiErrors";
import { constants } from "utils/config";

const {
  REACT_APP_CLIENT_ID: client_id,
  REACT_APP_CLIENT_SECRET: client_secret
} = process.env;
const domain = constants.domain;

const AuthContext = createContext();

export const LOCAL_STORE_USER_KEY = "sokolytics_dashboard_user";

export const useAuth = () => {
  const [user, setGAUser, deleteUser] = useLocalStorage(
    LOCAL_STORE_USER_KEY,
    null
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);

  const onLogin = async({ username, password }) => {
    let response = {};

    try {
      setError(null);
      setResponseMessage(null);
      setLoading(true);
      const credentials = { username, password, domain, client_id, client_secret };
      const {
        data: { user, token }
      } = await axiosClient.post("/login", credentials);
      setGAUser({ user, token });
      setLoading(false);
      response = { user, token };
    } catch (e) {
      let { description, status } = formatError(e);
      description =
        status === 401
          ? "Your username or password was incorrect"
          : description;
      setError(description);
      setLoading(false);
      response = { error: { description, status } };
      throw new Error(e);
    }
    return response;
  };

  const onLogout = () => {
    deleteUser();
    setError(null);
    setGAUser(null);
    setResponseMessage(null);
  };

  const onResetPassword = async({ username, notification_type }) => {
    try {
      setLoading(true);
      setError(null);
      setResponseMessage(null);
      const credentials = { username, notification_type, domain };
      const {
        data: { message }
      } = await axiosClient.post("/reset_password", credentials);
      setLoading(false);
      setResponseMessage(message);
      return true;
    } catch (e) {
      const { description } = formatError(e);
      setResponseMessage(null);
      setError(description);
      return false;
    }
  };

  return {
    error,
    setError,
    loading,
    user,
    onLogin,
    onLogout,
    responseMessage,
    setResponseMessage,
    onResetPassword
  };
};

export const AuthProvider = ({ children }) => {
  const auth = useContext(AuthContext);
  return <AuthContext.Provider value={auth}>{ children }</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
