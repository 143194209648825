import React, { lazy } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "hooks/authHook";

const ErrorBoundary = lazy(() =>
  import("../components/ErrorPages/ErrorBoundary")
);

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <ErrorBoundary>
            <Component {...props} />
          </ErrorBoundary>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  location: PropTypes.object
};

export default PrivateRoute;
