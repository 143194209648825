import axios from "axios";
import { get } from "lodash-es";

const { REACT_APP_BASEURL } = process.env;

const axiosClient = axios.create({
  baseURL: REACT_APP_BASEURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

axiosClient.interceptors.request.use(
  config => {
    const user = JSON.parse(localStorage.getItem("sokolytics_dashboard_user"));
    const activeSupplierId = localStorage.getItem("active_supplier_id");

    if (user) {
      const accessToken = get(user, "token.access_token");
      config.headers.common = {
        Authorization: `Bearer ${accessToken}`,
        "X-Authenticated-supplier-id": `${activeSupplierId}`
      };
    }

    return config;
  },
  error => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  response => response,
  err => {
    const url = get(err, "config.url");
    if (err?.response?.status === 401 && url !== "/login") {
      // clear user details only and retain supplier account details to allow a user to log back to the last selected supplier account
      localStorage.setItem("sokolytics_dashboard_user", null);
      window.location = "/login";
    }

    return Promise.reject(err);
  }
);

export const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

export default axiosClient;
