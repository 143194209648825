import { ReactComponent as Logo } from "./twiga-agent-logo.svg";
import { ReactComponent as SokoLyticsLogo } from "./Twiga-sokolytics-logo-01.svg";
import { ReactComponent as sidebarLogo } from "./sidebar.svg";
import UserIcon from "./user_icon.svg";
import PinIcon from "./pin_icon.svg";
import DoubleChevron from "./double-chevron.svg";
import MenuSVG from "./menu_icon.svg";
import BidsIcon from "./bids-icon.svg";
import { ReactComponent as NotificationBell } from "./notification-bell-icon.svg";
import NotificationMenu from "./notification-menu-icon.svg";
import DeliveryIcon from "./deliveries-icon.svg";
import BackButton from "./back_button.svg";
import ProfileIcon from "./user_icon2.svg";
import DropdownIcon from "./dropdown.svg";
import exitIcon from "./exit.svg";
import { ReactComponent as Error404Icon } from "./404_illustration.svg";
import ErrorIcon from "./error.svg";
import DeniedIcon from "./hide.svg";
import notificationIcon from "./notification-icon.svg";
import paymentIcon from "./payment-icon.svg";
import searchIcon from "./search-icon.svg";
import noImageIcon from "./no_image.svg";
import lowestBidIcon from "./lowest_bid_icon.svg";
import arrowIcon from "./arrow.svg";
import plusIcon from "./plus.svg";
import campaignsIcon from "./discount.svg";
import homeIcon from "./home.svg";
import salesIcon from "./profit.svg";
import distributionIcon from "./signs.svg";
import productIcon from "./sales.svg";
import pricingIcon from "./pricing.svg";
import settingsIcon from "./settings.svg";
import LoginBanner from "./sokolytics-login.svg";
import infoWarningIcon from "./info-icon-warning.svg";
import rightArrow from "./right.svg";
import decreaseArrow from "./decrease_arrow.svg";
import increaseArrow from "./increase_arrow.svg";
import profilePic from "./profile-pic.svg";
import successIcon from "./ok-success-icon.svg";
import createCampaign from "./create-campaign.png";
import bundlingIcon from "./bundling.svg";
import infoIcon from "./info.svg";
import nextIcon from "./next.svg";
import prevIcon from "./prev.svg";
import addIcon from "./add.svg";
import calendarIcon from "./calendar.svg";
import noData from "./no-data.svg";
import downArrow from "./down-arrow.svg";
import upArrow from "./up-arrow.svg";
import accessDenied from "./access-denied.svg";
import twigaLogo from "./twiga_logo.svg";
import orderInvoice from "./poi_icon.svg";
import payment from "./payment_slip.svg";
import goodsIcon from "./goods.svg";
import sokoYetuLogo from "./twiga2.svg";
// import sidebarLogo from "./sidebar.svg";

const icons = {
  UserIcon,
  PinIcon,
  exitIcon
};

const icon = name => (icons[name] ? icons[name] : null);

export {
  Logo,
  SokoLyticsLogo,
  DoubleChevron,
  UserIcon,
  PinIcon,
  MenuSVG,
  BackButton,
  BidsIcon,
  ProfileIcon,
  DropdownIcon,
  NotificationBell,
  NotificationMenu,
  DeliveryIcon,
  Error404Icon,
  ErrorIcon,
  DeniedIcon,
  exitIcon,
  notificationIcon,
  paymentIcon,
  searchIcon,
  noImageIcon,
  lowestBidIcon,
  arrowIcon,
  plusIcon,
  homeIcon,
  campaignsIcon,
  salesIcon,
  distributionIcon,
  productIcon,
  pricingIcon,
  settingsIcon,
  LoginBanner,
  infoWarningIcon,
  rightArrow,
  decreaseArrow,
  increaseArrow,
  profilePic,
  successIcon,
  createCampaign,
  bundlingIcon,
  infoIcon,
  nextIcon,
  prevIcon,
  addIcon,
  calendarIcon,
  noData,
  downArrow,
  upArrow,
  accessDenied,
  twigaLogo,
  orderInvoice,
  payment,
  goodsIcon,
  sokoYetuLogo,
  sidebarLogo

};

export default icon;
