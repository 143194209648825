import React, { useState } from "react";
import PropTypes from "prop-types";
import { UserAlertContext } from "context/userAlert";

const UserAlertProvider = ({ children }) => {
  const [alertDetails, setAlertDetails] = useState({
    firstName: "",
    lastName: "",
    userRole: "",
    display: false
  });
  return (
    <UserAlertContext.Provider
      value={{
        alertDetails,
        setAlertDetails
      }}

    >
      {children}
    </UserAlertContext.Provider>
  );
};

UserAlertProvider.propTypes = {
  children: PropTypes.node
};

export default UserAlertProvider;
