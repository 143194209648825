import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Container,
  Body,
  Contact,
  Button,
  ErrorText,
  ErrorImage,
  Wrapper,
  Title,
  Main,
  TryAgain
} from "./css/styles";
import { DeniedIcon, ErrorIcon, Error404Icon, accessDenied } from "../../assets/index";

export const PageNotFound = () => {
  return (
    <Container>
      <Wrapper>
        <ErrorText>404. PAGE NOT FOUND</ErrorText>
        <span>We cant seem to find the page you are looking for</span>
        <Link to="/">
          <Button>Go to Home Page</Button>
        </Link>
      </Wrapper>
      <ErrorImage>
        <Error404Icon />
      </ErrorImage>
    </Container>
  );
};

export const NoPermission = () => {
  return (
    <Container>
      <Body>
        <img src={DeniedIcon} alt="Error Icon" />
        <h4>ACCESS DENIED</h4>
        <h4>
          Sorry, but you don’t have the necessary permissions to access this
          page.
        </h4>
        <Contact>
          <Link>Previous Page</Link> | <Link to="/">Go to Home page</Link>{" "}
        </Contact>
      </Body>
    </Container>
  );
};

export const ErrorPage = ({ boundaryError }) => {
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Container>
      <Body>
        <img src={ErrorIcon} alt="Error Icon" />
        <h4>SNAP</h4>
        <h3>{boundaryError && "We encountered a problem displaying this page"}</h3>
        <h4>You can try refreshing the page or file a bug report with your admin</h4>

        <Button onClick={refreshPage}>Retry</Button>
      </Body>
    </Container>
  );
};

export const AccessDenied = ({ message = "Sorry, it seems you currently don’t have access to any reports." }) => {
  return (
    <Main>
      <img src={accessDenied} alt="access-denied icon" />
      <Title>Your products have not been added.</Title>
      <span>{message}</span><br />
      <span>Please contact your account administrator for assistance.</span><br />
      <TryAgain onClick={() => window.location.reload()}>Try Again</TryAgain>
    </Main>
  );
};

ErrorPage.propTypes = {
  boundaryError: PropTypes.string
};

AccessDenied.propTypes = {
  message: PropTypes.string

};
